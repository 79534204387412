<template>
  <section v-if="affichageMobile" class="mb-3">
    <!-- Template si tableau en cours de chargement -->
    <template v-if="busy === true || busy === 'true'">
      <div class="text-center text-warning my-3">
        <b-spinner class="align-middle"></b-spinner>
        <br />
        <strong>Chargement en cours...</strong>
      </div>
    </template>
    <!-- Template si pas de contenu à afficher -->
    <template v-else-if="items.length <= 0">
      <BubbleComponent>
        <slot name="empty">
          <p>
            <strong>Waouh !</strong>
          </p>
          <p v-if="false">
            <!-- FIXME Forte latence due au chargement de l'image -->
            <img style="max-width: 50vw; height: auto" src="/img/empty.gif" />
          </p>
          <p>
            <em>Il n'y a rien &agrave; afficher ici... ;-)</em>
            <br />
            <em>Pensez &agrave; v&eacute;rifier vos filtres !</em>
          </p>
        </slot>
      </BubbleComponent>
    </template>
    <!-- Template pour afficher le contenu mobile -->
    <template v-else>
      <template v-for="item in items">
        <slot name="mobile-card" v-bind="{ item }"></slot>
      </template>
    </template>
  </section>
  <section v-else class="mb-3">
    <b-table
      :id="id"
      table-class="paginated-table mb-0"
      thead-class="sticky-top"
      head-variant="light"
      hover
      :stacked="stacked"
      :busy="busy"
      sort-icon-left
      no-local-sorting
      show-empty
      :fields="computedFields"
      :items="items"
      :sort-by="computedSortBy"
      :sort-desc="computedSortDesc"
      @sort-changed="sortChanged"
    >
      <!-- Template si tableau en cours de chargement -->
      <template #table-busy>
        <div class="text-center text-warning my-3">
          <b-spinner class="align-middle"></b-spinner>
          <br />
          <strong>Chargement en cours...</strong>
        </div>
      </template>
      <!-- Template si pas de contenu à afficher -->
      <template #empty>
        <BubbleComponent>
          <slot name="empty">
            <p>
              <strong>Waouh !</strong>
            </p>
            <p v-if="false">
              <!-- FIXME Forte latence due au chargement de l'image -->
              <img src="/img/empty.gif" />
            </p>
            <p>
              <em>Il n'y a rien &agrave; afficher ici... ;-)</em>
              <br />
              <em>Pensez &agrave; v&eacute;rifier vos filtres !</em>
            </p>
          </slot>
        </BubbleComponent>
      </template>
      <!-- Templates des colonnes pour afficher le contenu -->
      <template v-for="slotName in tableSlots" #[slotName]="context">
        <slot :name="slotName" v-bind="context"></slot>
      </template>
    </b-table>
  </section>
</template>

<script>
import { v4 as uuidv4 } from "uuid";
import BubbleComponent from "./BubbleComponent.vue";
import UtilsService from "../services/utils.service";
export default {
  name: "PaginatedTable",
  components: { BubbleComponent },
  /**
   * fields = paramétrage des champs de la table
   * stacked = valeur de la propriété stacked de la table
   * busy = valeur de la propriété busy de la table
   * sortBy = champ de tri
   * sortDesc = sens de tri
   * items = contenu trié
   */
  props: ["fields", "stacked", "busy", "sortBy", "sortDesc", "items"],
  data() {
    return {
      id: `table-${uuidv4()}`,
      // Slots traités à part
      specialSlots: ["mobile-card", "table-busy", "empty", "pagination"],
    };
  },
  computed: {
    affichageMobile() {
      return (
        this.$store.getters["affichageMobile"] &&
        Object.keys(this.$scopedSlots).find((name) => name === "mobile-card")
      );
    },
    computedFields() {
      return (UtilsService.deepCopy(this.fields) || []).map((f) => {
        // Centrage vertical systématique des libellés de colonnes
        if (!f.thClass) {
          f.thClass = "align-middle";
        } else if (typeof f.thClass === "string") {
          f.thClass = `${f.thClass} align-middle`;
        } else if (Array.isArray(f.thClass)) {
          f.thClass.push("align-middle");
        }

        // Alignement horizontal du contenu
        if (f.alignH) {
          f.thClass = `${f.thClass ?? ""} text-${f.alignH}`;
          f.tdClass = `${f.tdClass ?? ""} text-${f.alignH}`;
        }

        // Comportement responsive
        if (f.breakpoint) {
          f.thClass = `${f.thClass ?? ""} d-none d-${f.breakpoint}-table-cell`;
          f.tdClass = `${f.tdClass ?? ""} d-none d-${f.breakpoint}-table-cell`;
        }

        return f;
      });
    },
    computedSortBy() {
      // Si le pageable en entrée implémente le tri multiple
      // Alors on ne garde que la 1ère propriété (b-table ne le gère pas)
      return Array.isArray(this.sortBy) ? this.sortBy[0] : this.sortBy;
    },
    computedSortDesc() {
      // Si le pageable en entrée implémente le tri multiple
      // Alors on ne garde que la 1ère propriété (b-table ne le gère pas)
      return Array.isArray(this.sortDesc)
        ? this.sortDesc[0] ?? false
        : this.sortDesc;
    },
    tableSlots() {
      return Object.keys(this.$scopedSlots).filter(
        (name) => !this.specialSlots.includes(name)
      );
    },
  },
  methods: {
    sortChanged(tableContext) {
      if (tableContext.sortBy) {
        let sort = {};
        // Si le pageable en entrée implémente le tri multiple
        // Alors on renvoie les propriétés de tri sous forme de tableau
        sort.sortBy = Array.isArray(this.sortBy)
          ? [tableContext.sortBy]
          : tableContext.sortBy;
        sort.sortDesc = Array.isArray(this.sortDesc)
          ? [tableContext.sortDesc]
          : tableContext.sortDesc;
        this.$emit("sortChanged", sort);
      }
    },
  },
};
</script>
